import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

import { TableColumnModelExtended } from 'common/src/models/table-column.model';
import { TransactionTypeEnum } from '../../enums';
import { FiltersControlsNamesEnum, FiltersGroupModel } from 'common/src/modules/filters';

export function getTransactionsListFiltersConfig(
  binLocationsFrom$: Observable<string[]>,
  binLocationsTo$: Observable<string[]>,
  batchesList$: Observable<any>,
  documentsList$: Observable<any>,
  ): FiltersGroupModel[] {
  return [
    {
      disabled: true,
      filters: [
        {
          label: 'POSITIONS.PRODUCT',
          controlName: 'productId',
          controlType: FiltersControlsNamesEnum.PRODUCT,
          // availableProductTypes: [ProductTypes.GOODS, ProductTypes.SERVICES, ProductTypes.DIGITAL]
        },
        {
          label: 'COLUMN.BATCH',
          controlName: 'batchId',
          bindValue: 'id',
          controlClass: 'col-6 mw-272',
          controlType: FiltersControlsNamesEnum.LINKED_DOCUMENT,
          allowSelectAll: true,
          placeholder: 'FORM.SELECT_BATCH',
          observableList: batchesList$,
        },
      ]
    },
    {
      title: 'REPORTING_PERIODS.LOCATION',
      filters: [
        {
          label: 'FORM.FROM',
          placeholder: 'FORM.FROM',
          controlName: 'from',
          containerClass: 'col-6 mb-0',
          controlType: FiltersControlsNamesEnum.DROPDOWN_OBSERVABLE_STRING,
          observableList: binLocationsFrom$,
          allowSelectAll: true,
        },
        {
          label: 'FORM.TO',
          placeholder: 'FORM.TO',
          controlName: 'to',
          containerClass: 'col-6',
          controlType: FiltersControlsNamesEnum.DROPDOWN_OBSERVABLE_STRING,
          observableList: binLocationsTo$,
          allowSelectAll: true,
        },
      ]
    },
    {
      title: 'DOC_BLOCK.PROPERTIES',
      filters: [
        {
          label: 'COLUMN.TYPE',
          controlName: 'type',
          controlType: FiltersControlsNamesEnum.BASE_DROPDOWN,
          controlClass: 'col-6 mw-272',
          placeholder: 'FORM.SELECT_TYPE',
          baseDropdownItems: [
            {label: 'FORM.STOCK_TRANSFER', value: TransactionTypeEnum.STOCK_TRANSFER},
            {label: 'COMMON.GOODS_RECEIPT', value: TransactionTypeEnum.GOODS_RECEIPT},
            {label: 'COMMON.WRITE_OFF_PRODUCT', value: TransactionTypeEnum.WRITE_OFF_PRODUCT},
            {label: 'FORM.BOOK_PRODUCT', value: TransactionTypeEnum.BOOK_PRODUCT},
            {label: 'COMMON.SHIPPING_PRODUCT', value: TransactionTypeEnum.SHIPPING_PRODUCT},
          ],
        },
        {
          label: 'COLUMN.LINKED_DOCUMENTS',
          controlName: 'linkedDocument',
          controlClass: 'col-6 mw-272',
          placeholder: 'FORM.SELECT_LINKED_DOCUMENT',
          controlType: FiltersControlsNamesEnum.DROPDOWN_OBSERVABLE_STRING,
          allowSelectAll: true,
          observableList: documentsList$,
        },
        {
          label: 'FORM.CREATION_DATE',
          controlName: 'created',
          lastDates: true,
          controlType: FiltersControlsNamesEnum.DATE_PERIODS,
        },
        {
          label: 'FORM.EMPLOYEE',
          controlName: 'employeeId',
          controlClass: 'col-6 mw-272',
          controlType: FiltersControlsNamesEnum.EMPLOYEE,
        },
      ]
    }
  ];
}

export function getTransactionsListColumns(translateService: TranslateService): TableColumnModelExtended[] {
 return [
   {
     cellTemplate: 'numberRows',
     cellClass: 'center',
     name: 'Nr.',
     prop: 'columnNumber',
     width: 40,
     maxWidth: 40,
     sortable: false,
     resizeable: false,
     headerClass: 'center',
     customizable: true,
   },
   {
     cellTemplate: 'primary',
     headerTemplate: 'headerPrimary',
     name: 'COLUMN.TRANSACTION_ID',
     prop: 'runpleId',
     sortable: true,
     cellClass: 'center',
     width: 146,
     maxWidth: 146,
   },
   {
     cellTemplate: 'primary',
     headerTemplate: 'headerPrimary',
     name: 'COLUMN.TYPE',
     prop: 'typeLabel',
     sortable: true,
     cellClass: 'center',
     width: 147,
     maxWidth: 147,
     customizable: true,
   },
   {
     cellTemplate: 'withLinkPrimary',
     headerTemplate: 'headerPrimary',
     name: 'COLUMN.PRODUCT_NAME',
     prop: 'productNameLink',
     preventTranslate: true,
     cellClass: 'link-primary inline',
     sortable: true,
     customizable: true,
   },
   {
     cellTemplate: 'primaryWithNumber',
     headerTemplate: 'headerPrimary',
     name: 'COLUMN.QUANTITY',
     prop: 'quantity',
     sortable: true,
     postFixText: translateService.instant('FORM.PCS'),
     cellClass: 'right font-weight-500',
     width: 112,
     maxWidth: 112,
     customizable: true,
   },
   {
     cellTemplate: 'primary',
     headerTemplate: 'headerPrimary',
     name: 'COLUMN.BATCH',
     prop: 'batchRunpleId',
     sortable: true,
     customizable: true,
   },
   {
     cellTemplate: 'primary',
     headerTemplate: 'headerPrimary',
     name: 'FORM.FROM',
     prop: 'from',
     sortable: true,
     width: 152,
     maxWidth: 152,
     customizable: true,
   },
   {
     cellTemplate: 'primary',
     headerTemplate: 'headerPrimary',
     name: 'FORM.TO',
     prop: 'to',
     sortable: true,
     width: 152,
     maxWidth: 152,
     customizable: true,
   },
   {
     cellTemplate: 'withLinkPrimary',
     headerTemplate: 'headerPrimary',
     cellClass: 'link-primary cursor-pointer',
     name: 'COLUMN.DOCUMENT_1',
     prop: 'documentLink',
     width: 172,
     maxWidth: 172,
     sortable: true,
     customizable: true,
   },
   {
     cellTemplate: 'withAvatarArrowRight',
     headerTemplate: 'headerPrimary',
     cellClass: 'cursor-pointer p-0',
     name: 'COLUMN.EMPLOYEE',
     prop: 'employee_data',
     width: 210,
     maxWidth: 210,
     customizable: true,
   },
   {
     cellTemplate: 'withDate',
     headerTemplate: 'headerPrimary',
     name: 'COLUMN.CREATED',
     prop: 'createdAt',
     sortable: true,
     customizable: true,
   },
 ]
}

export const TRANSACTIONS_TYPE_LABELS = {
  [TransactionTypeEnum.STOCK_TRANSFER]: 'FORM.STOCK_TRANSFER',
  [TransactionTypeEnum.CANCEL_WAREHOUSING]: 'INCOMING_DELIVERY.CANCEL_WAREHOUSING',
  [TransactionTypeEnum.BOOK_PRODUCT]: 'FORM.BOOK_PRODUCT',
  [TransactionTypeEnum.WRITE_OFF_PRODUCT]: 'COMMON.WRITE_OFF_PRODUCT',
  [TransactionTypeEnum.GOODS_RECEIPT]: 'COMMON.GOODS_RECEIPT',
  [TransactionTypeEnum.SHIPPING_PRODUCT]: 'COMMON.SHIPPING_PRODUCT',
}
